<!-- wxlogin.vue -->
<template>
  <div class="wrapper">
    <div class="loginbox">
      <Form ref="formInline" class="formlogin" :model="formInline" :rules="ruleInline" inline>
        <FormItem prop="username">
          <i-input type="text" v-model="formInline.username" placeholder="用户名">
            <Icon type="ios-call-outline" slot="prepend"></Icon>
          </i-input>
        </FormItem>
        <FormItem prop="password">
          <i-input type="password" v-model="formInline.password" placeholder="密码">
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
          </i-input>
        </FormItem>
        <FormItem class="loginbtn">
          <Button @click="handleSubmit('formInline')">登录</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import { post } from '@/apis/restUtils';
import { Base64 } from 'js-base64';

export default {
  data() {
    return {
      formInline: {
        openid: this.$route.query.openid || '', // 从路由获取 code
        username: '',
        password: ''
      },
      ruleInline: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    };
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          const parameter = {
            openid: this.formInline.openid,
            username: this.formInline.username,
            password: Base64.encode(this.formInline.password)
          };
          post('/wx/signup', parameter, (response) => {
            if (response && response.data && response.data.status === 'success') {
              let user = response.data.entity;
              localStorage.setItem('UserName', user.username);
              localStorage.setItem('UserId', user.id);
              localStorage.setItem('Flag', true);
              this.$Message.success('登录成功!');
              this.$router.push('/wxindex');
            } else {
              this.$Message.error(response.data.result);
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginbox {
  width: 75%;
  height: auto;
  margin: 10% auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formlogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.formlogin > div {
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.loginbtn {
  width: 60%;
}

.loginbtn > div > button {
  width: 100%;
  height: 80px;
  font-size: 1.5rem;
  background-color: #ffffff;
  color: #313131;
  border: 0;
}
</style>